exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-black-js": () => import("./../../../src/pages/black.js" /* webpackChunkName: "component---src-pages-black-js" */),
  "component---src-pages-celulite-js": () => import("./../../../src/pages/celulite.js" /* webpackChunkName: "component---src-pages-celulite-js" */),
  "component---src-pages-exclusive-renewal-js": () => import("./../../../src/pages/exclusive-renewal.js" /* webpackChunkName: "component---src-pages-exclusive-renewal-js" */),
  "component---src-pages-forever-js": () => import("./../../../src/pages/forever.js" /* webpackChunkName: "component---src-pages-forever-js" */),
  "component---src-pages-groupon-js": () => import("./../../../src/pages/groupon.js" /* webpackChunkName: "component---src-pages-groupon-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-preview-details-tsx": () => import("./../../../src/pages/live-preview-details.tsx" /* webpackChunkName: "component---src-pages-live-preview-details-tsx" */),
  "component---src-pages-live-preview-tsx": () => import("./../../../src/pages/live-preview.tsx" /* webpackChunkName: "component---src-pages-live-preview-tsx" */),
  "component---src-pages-lody-oxy-js": () => import("./../../../src/pages/lody-oxy.js" /* webpackChunkName: "component---src-pages-lody-oxy-js" */),
  "component---src-pages-nowosc-js": () => import("./../../../src/pages/nowosc.js" /* webpackChunkName: "component---src-pages-nowosc-js" */),
  "component---src-pages-nowyrok-js": () => import("./../../../src/pages/nowyrok.js" /* webpackChunkName: "component---src-pages-nowyrok-js" */),
  "component---src-pages-onboarding-js": () => import("./../../../src/pages/onboarding.js" /* webpackChunkName: "component---src-pages-onboarding-js" */),
  "component---src-pages-podsumowanie-js": () => import("./../../../src/pages/podsumowanie.js" /* webpackChunkName: "component---src-pages-podsumowanie-js" */),
  "component---src-pages-przykladowe-menu-classic-tsx": () => import("./../../../src/pages/przykladowe-menu-classic.tsx" /* webpackChunkName: "component---src-pages-przykladowe-menu-classic-tsx" */),
  "component---src-pages-przykladowe-menu-vege-fish-js": () => import("./../../../src/pages/przykladowe-menu-vege-fish.js" /* webpackChunkName: "component---src-pages-przykladowe-menu-vege-fish-js" */),
  "component---src-pages-przykladowe-menu-vege-js": () => import("./../../../src/pages/przykladowe-menu-vege.js" /* webpackChunkName: "component---src-pages-przykladowe-menu-vege-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-tiktok-js": () => import("./../../../src/pages/tiktok.js" /* webpackChunkName: "component---src-pages-tiktok-js" */),
  "component---src-pages-treningi-js": () => import("./../../../src/pages/treningi.js" /* webpackChunkName: "component---src-pages-treningi-js" */),
  "component---src-pages-wielkie-zmiany-js": () => import("./../../../src/pages/wielkie-zmiany.js" /* webpackChunkName: "component---src-pages-wielkie-zmiany-js" */),
  "component---src-pages-wyzwanie-js": () => import("./../../../src/pages/wyzwanie.js" /* webpackChunkName: "component---src-pages-wyzwanie-js" */),
  "component---src-templates-page-builder-tsx": () => import("./../../../src/templates/pageBuilder.tsx" /* webpackChunkName: "component---src-templates-page-builder-tsx" */)
}

